import React, { useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled'

import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Card } from '@amzn/stencil-react-components/card'
import { Input, InputWrapper, Select } from '@amzn/stencil-react-components/form'
import {
    IconBin,
    IconChevronDown,
    IconChevronUp,
    IconPlus,
} from '@amzn/stencil-react-components/icons'
import { Col, Flex, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import {
    TableElement,
    TbodyElement,
    TdElement,
    TheadElement,
    ThElement,
    TrElement,
} from '@amzn/stencil-react-components/table'
import { Label, Text } from '@amzn/stencil-react-components/text'

import { ON_TEST_ENVIRONMENT } from 'src/config.app'
import { applyChange, Change } from 'src/hooks/DTOEditor'
import {
    BucketAndCupSelectionStepDTO,
    BucketSelectionRule,
    CupSelectionRule,
    defaultTimerConfig,
    PagePattern,
    PagePatternTag,
    SinceMessageDeliveredDTO,
    TimerEventType,
} from 'src/models/dto/activities/BucketsAndCupsGroupDTO'
import {
    pagePatternTagsToString,
    parsePagePatternTags,
} from 'src/pages/module-builder/buckets-and-cups-editor/PagePatternTagsParser'
import { FocusId, focusOnId } from 'src/pages/module-builder/focus'
import { ItemEditorLabel } from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { BucketsAndCupsGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/BucketsAndCupsGroupHandler'

export interface SelectionStepInputProps<T> {
    id: string
    value?: T
    updateValue: (nextValue: T) => void
    dataTestId: string
    disabled?: boolean
}

export interface SelectionStepEnumInputProps extends SelectionStepInputProps<string> {
    possibleValues: string[]
}

const AutoWidthSelect = styled(Select)({
    overflow: 'auto',
    fontSize: '0.85em',
})

function addSelectionStepId(entityId: string) {
    return ('add-selection-step-' + entityId) as FocusId
}

export function SelectionStepEnumInput({
    id,
    value,
    updateValue,
    possibleValues,
    dataTestId,
    disabled,
}: SelectionStepEnumInputProps) {
    return (
        <AutoWidthSelect
            id={id}
            labelId={`${id}-label`}
            width='100%'
            dataTestId={dataTestId}
            value={value}
            disabled={disabled}
            onChange={(e: string) => {
                if (e) {
                    updateValue(e)
                }
            }}
            options={Object.values(possibleValues)}
        />
    )
}

const SelectionStepNumberInput = ({
    id,
    value,
    updateValue,
    dataTestId,
    disabled = false,
}: SelectionStepInputProps<number>) => {
    const invalid = value === undefined ? true : Number.isNaN(+value)

    return (
        <Input
            disabled={disabled}
            id={id}
            key={dataTestId}
            dataTestId={dataTestId}
            error={invalid}
            aria-invalid={invalid}
            required={true}
            type='number'
            min='0'
            value={value}
            width='100%'
            onChange={(e) => {
                const number = +e?.target?.value
                if (!isNaN(number)) {
                    updateValue(number)
                }
            }}
        />
    )
}

interface SelectionStepInputLabelProps {
    text: string
    htmlFor: string
}

function SelectionStepInputLabel({ text, htmlFor }: SelectionStepInputLabelProps) {
    return (
        <Label htmlFor={htmlFor} id={`${htmlFor}-label`}>
            <Text fontSize={'T100'} color={'neutral70'}>
                {text}
            </Text>
        </Label>
    )
}

export interface SelectionStepProps {
    selectionStep: BucketAndCupSelectionStepDTO
    selectionStepIndex: number
    entityId: string
    showLabel?: boolean
    isTimerEnabled: boolean
    editDisabled?: boolean
}

export function BucketRankInput({
    showLabel,
    selectionStepIndex,
    entityId,
    selectionStep,
    editDisabled,
}: SelectionStepProps) {
    const id = `bucket-rank-${selectionStepIndex}`
    const label = <SelectionStepInputLabel text='Bucket Rank' htmlFor={id} />
    return (
        <>
            {showLabel ? label : <ScreenReaderOnly>{label}</ScreenReaderOnly>}
            <SelectionStepNumberInput
                id={id}
                dataTestId={id}
                disabled={editDisabled}
                value={selectionStep.bucketRank}
                updateValue={(value: number) => {
                    BucketsAndCupsGroupHandler.updateSelectionStep(entityId, selectionStepIndex, {
                        ...selectionStep,
                        bucketRank: value,
                    })
                }}
            />
        </>
    )
}

export function BucketSelectionRuleInput({
    showLabel,
    selectionStepIndex,
    entityId,
    selectionStep,
    editDisabled,
}: SelectionStepProps) {
    const id = `bucket-selection-rule-${selectionStepIndex}`
    const label = <SelectionStepInputLabel text='Bucket Selection Rule' htmlFor={id} />
    return (
        <>
            {showLabel ? label : <ScreenReaderOnly>{label}</ScreenReaderOnly>}
            <SelectionStepEnumInput
                id={id}
                dataTestId={id}
                value={selectionStep.bucketSelectionRule}
                disabled={editDisabled}
                updateValue={(value: string) => {
                    const updated = {
                        ...selectionStep,
                        bucketSelectionRule: value as BucketSelectionRule,
                    }
                    BucketsAndCupsGroupHandler.updateSelectionStep(
                        entityId,
                        selectionStepIndex,
                        updated
                    )
                }}
                possibleValues={Object.values(BucketSelectionRule)}
            />
        </>
    )
}

export function CupRankInput({
    showLabel,
    selectionStepIndex,
    entityId,
    selectionStep,
    editDisabled,
}: SelectionStepProps) {
    const id = `cup-rank-${selectionStepIndex}`
    const label = <SelectionStepInputLabel text='Cup Rank' htmlFor={id} />
    return (
        <>
            {showLabel ? label : <ScreenReaderOnly>{label}</ScreenReaderOnly>}
            <SelectionStepNumberInput
                id={id}
                dataTestId={id}
                disabled={editDisabled}
                value={selectionStep.cupRank}
                updateValue={(value: number) => {
                    BucketsAndCupsGroupHandler.updateSelectionStep(entityId, selectionStepIndex, {
                        ...selectionStep,
                        cupRank: value,
                    })
                }}
            />
        </>
    )
}

export function CupSelectionRuleInput({
    showLabel,
    selectionStepIndex,
    entityId,
    selectionStep,
    editDisabled,
}: SelectionStepProps) {
    const id = `cup-selection-rule-${selectionStepIndex}`
    const label = <SelectionStepInputLabel text='Cup Selection Rule' htmlFor={id} />
    return (
        <>
            {showLabel ? label : <ScreenReaderOnly>{label}</ScreenReaderOnly>}
            <SelectionStepEnumInput
                id={id}
                dataTestId={id}
                value={selectionStep.cupSelectionRule}
                disabled={
                    selectionStep.bucketSelectionRule === BucketSelectionRule.RESUME || editDisabled
                }
                updateValue={(value: string) => {
                    BucketsAndCupsGroupHandler.updateSelectionStep(entityId, selectionStepIndex, {
                        ...selectionStep,
                        cupSelectionRule: value as CupSelectionRule,
                    })
                }}
                possibleValues={Object.values(CupSelectionRule)}
            />
        </>
    )
}

export function CupsToPickFromBucketInput({
    showLabel,
    selectionStepIndex,
    entityId,
    selectionStep,
    isTimerEnabled,
    editDisabled,
}: SelectionStepProps) {
    const id = `number-of-cups-to-pick-from-bucket-${selectionStepIndex}`
    const label = <SelectionStepInputLabel text='Cups to Pick from Bucket' htmlFor={id} />
    return (
        <>
            {showLabel ? label : <ScreenReaderOnly>{label}</ScreenReaderOnly>}
            <SelectionStepNumberInput
                id={id}
                disabled={
                    (isTimerEnabled &&
                        selectionStep.bucketSelectionRule === BucketSelectionRule.RESUME) ||
                    editDisabled
                }
                dataTestId={id}
                value={selectionStep.numberOfCupsToPickFromBucket}
                updateValue={(value: number) => {
                    BucketsAndCupsGroupHandler.updateSelectionStep(entityId, selectionStepIndex, {
                        ...selectionStep,
                        numberOfCupsToPickFromBucket: value,
                    })
                }}
            />
        </>
    )
}

function ItemsToPickFromCupInput({
    showLabel,
    selectionStepIndex,
    entityId,
    selectionStep,
    editDisabled,
}: SelectionStepProps) {
    const id = `number-of-items-to-pick-from-cup-${selectionStepIndex}`
    const label = <SelectionStepInputLabel text='Items to Pick from Cup' htmlFor={id} />
    return (
        <>
            {showLabel ? label : <ScreenReaderOnly>{label}</ScreenReaderOnly>}
            <SelectionStepNumberInput
                id={id}
                dataTestId={id}
                value={selectionStep.numberOfItemsToPickFromCup}
                disabled={editDisabled}
                updateValue={(value: number) => {
                    BucketsAndCupsGroupHandler.updateSelectionStep(entityId, selectionStepIndex, {
                        ...selectionStep,
                        numberOfItemsToPickFromCup: value,
                    })
                }}
            />
        </>
    )
}

function GroupSizeInput({
    showLabel,
    selectionStepIndex,
    entityId,
    selectionStep,
    editDisabled,
}: SelectionStepProps) {
    const id = `group-size-${selectionStepIndex}`
    const label = <SelectionStepInputLabel text='Group size' htmlFor={id} />
    return (
        <>
            {showLabel ? label : <ScreenReaderOnly>{label}</ScreenReaderOnly>}
            <SelectionStepNumberInput
                id={id}
                dataTestId={id}
                value={selectionStep.groupSize}
                disabled={editDisabled}
                updateValue={(value: number) => {
                    BucketsAndCupsGroupHandler.updateSelectionStep(entityId, selectionStepIndex, {
                        ...selectionStep,
                        groupSize: value,
                    })
                }}
            />
        </>
    )
}

export function TimerConfigTypeInput({
    showLabel,
    selectionStepIndex,
    entityId,
    selectionStep,
    isTimerEnabled,
    editDisabled,
}: SelectionStepProps) {
    const id = `bucket-selection-rule-timer-config-type-${selectionStepIndex}`
    const label = <SelectionStepInputLabel text='Timer Config Type' htmlFor={id} />
    return (
        <>
            {showLabel ? label : <ScreenReaderOnly>{label}</ScreenReaderOnly>}
            <SelectionStepEnumInput
                id={id}
                dataTestId={id}
                value={selectionStep.timerConfig?.type ?? TimerEventType.UPON_SUBMISSION}
                disabled={!isTimerEnabled || editDisabled}
                updateValue={(value: string) => {
                    BucketsAndCupsGroupHandler.updateSelectionStep(entityId, selectionStepIndex, {
                        ...selectionStep,
                        timerConfig: defaultTimerConfig(
                            (value as never) || TimerEventType.UPON_SUBMISSION
                        ),
                    })
                }}
                possibleValues={Object.values(TimerEventType)}
            />
        </>
    )
}

export function ElapsedTimeInput({
    showLabel,
    selectionStepIndex,
    entityId,
    selectionStep,
    isTimerEnabled,
    editDisabled,
}: SelectionStepProps) {
    const id = `elapsed-time-input-${selectionStepIndex}`
    const label = <SelectionStepInputLabel text='Elapsed Time (Seconds)' htmlFor={id} />
    return (
        <>
            {showLabel ? label : <ScreenReaderOnly>{label}</ScreenReaderOnly>}
            <SelectionStepNumberInput
                id={id}
                dataTestId={id}
                disabled={
                    (isTimerEnabled
                        ? selectionStep.timerConfig?.type !==
                          TimerEventType.SINCE_LAST_MESSAGE_DELIVERED
                        : true) || editDisabled
                }
                value={
                    (selectionStep.timerConfig as never as SinceMessageDeliveredDTO | undefined)
                        ?.elapsedTimeInSeconds ?? 0
                }
                updateValue={(value: number) => {
                    BucketsAndCupsGroupHandler.updateSelectionStep(entityId, selectionStepIndex, {
                        ...selectionStep,
                        timerConfig: {
                            type: TimerEventType.SINCE_LAST_MESSAGE_DELIVERED,
                            elapsedTimeInSeconds: value,
                        },
                    })
                }}
            />
        </>
    )
}

function DeleteSelectionStepButton({
    entityId,
    selectionStepIndex,
    selectionStepsLength,
    editDisabled,
}: {
    entityId: string
    selectionStepIndex: number
    selectionStepsLength: number
    editDisabled?: boolean
}) {
    const onlyOneSelectionStep = selectionStepsLength === 1
    const renderModal = ({ close }: { close: () => void }) => {
        return (
            <ModalContent
                dataTestId={'delete-selection-step-modal'}
                titleText={`Delete Selection Step ${selectionStepIndex + 1}`}
                buttons={[
                    <Button
                        key='delete-selection-step-submit'
                        dataTestId='delete-selection-step-submit'
                        onClick={() => {
                            BucketsAndCupsGroupHandler.deleteSelectionStep(
                                entityId,
                                selectionStepIndex
                            )
                            close()
                        }}
                        isDestructive
                    >
                        Yes, delete selection step
                    </Button>,
                    <Button
                        key='close-modal'
                        dataTestId='close-modal'
                        onClick={close}
                        variant={ButtonVariant.Primary}
                    >
                        No, keep selection step
                    </Button>,
                ]}
            >
                <Col gridGap='S400'>
                    <Text>
                        Do you want to delete selection step {selectionStepIndex + 1}? This cannot
                        be undone.
                    </Text>
                </Col>
            </ModalContent>
        )
    }

    return (
        <WithModal renderModal={renderModal}>
            {({ open }) => {
                const onClick = () => {
                    focusOnId(addSelectionStepId(entityId), undefined, false)
                    requestAnimationFrame(() => {
                        requestAnimationFrame(open)
                    })
                }

                return (
                    <Button
                        aria-label={'delete'}
                        aria-disabled={onlyOneSelectionStep || editDisabled}
                        dataTestId='delete-selection-step'
                        icon={<IconBin aria-hidden />}
                        variant={ButtonVariant.Tertiary}
                        isDestructive
                        onClick={onClick}
                    />
                )
            }}
        </WithModal>
    )
}

const renderItemEditorLabel = ({
    htmlFor: id,
    labelId,
    children: labelText,
}: {
    htmlFor: string
    labelId?: string
    color: string
    children: React.ReactNode
}) => <ItemEditorLabel id={id} labelId={labelId} labelText={labelText} />

interface PagePatternEditorProps {
    entityId: string
    index: number
    pagePatternIndex: number
    pagePattern: PagePattern
    isCard: boolean
    error?: { pageVariable?: string; tags?: string }
}

const PagePatternEditorInner = ({
    entityId,
    index,
    pagePatternIndex: i,
    pagePattern,
    isCard,
    error: validationError,
}: PagePatternEditorProps) => {
    const pageVariableError = validationError?.pageVariable
    const tagsError = validationError?.tags
    const [error, setError] = useState(false)
    const [patternString, setPatternString] = useState(() =>
        pagePatternTagsToString(pagePattern.tags)
    )
    const deletePagePattern = useCallback(() => {
        BucketsAndCupsGroupHandler.updatePagePatterns(entityId, index, (pagePatterns1) => {
            const left = (pagePatterns1 || []).slice(0, i)
            const right = (pagePatterns1 || []).slice(i + 1)
            return [...left, ...right]
        })
    }, [entityId, index, i])
    const setPagePattern = useCallback(
        (change: Change<PagePattern>) => {
            BucketsAndCupsGroupHandler.updatePagePatterns(entityId, index, (pagePatterns1) => {
                const newPagePatterns = [...(pagePatterns1 || [])]
                newPagePatterns[i] = applyChange(newPagePatterns[i], change)
                return newPagePatterns
            })
        },
        [entityId, index, i]
    )
    const idPrefix = `${entityId}-page-pattern-${index}-${i}`

    return (
        <Flex flexDirection={isCard ? 'column' : 'row'} gridGap='S200' width='100%' key={i}>
            <Col flex='1 1 40px'>
                <InputWrapper
                    error={!!pageVariableError}
                    id={`${idPrefix}-page-variable-name`}
                    dataTestId={`${idPrefix}-page-variable-name`}
                    labelText={`Page Variable Name ${i + 1}`}
                    renderLabel={renderItemEditorLabel}
                    footer={pageVariableError}
                >
                    {(props) => (
                        <Input
                            {...props}
                            placeholder='PageX'
                            value={pagePattern.pageVariable}
                            onChange={(e) =>
                                setPagePattern((pagePattern1) => ({
                                    ...pagePattern1,
                                    pageVariable: e.target.value,
                                }))
                            }
                        />
                    )}
                </InputWrapper>
            </Col>
            <Col flex='2 1 80px'>
                <InputWrapper
                    id={`${idPrefix}-pattern`}
                    dataTestId={`${idPrefix}-pattern`}
                    labelText={`Pattern ${i + 1}`}
                    renderLabel={renderItemEditorLabel}
                    error={!!tagsError || error}
                    footer={tagsError}
                >
                    {(props) => (
                        <Input
                            {...props}
                            required
                            placeholder='Table, MC{R1, R2}'
                            value={patternString}
                            dataTestId={`page-scoring-pattern-${index}-${i}`}
                            onChange={(e) => {
                                setError(false)
                                setPatternString(e.target.value)
                                const newPagePatternTags = parsePagePatternTags(e.target.value)
                                if (newPagePatternTags) {
                                    setPagePattern({
                                        ...pagePattern,
                                        tags: newPagePatternTags,
                                    })
                                } else {
                                    setError(true)
                                }
                            }}
                        />
                    )}
                </InputWrapper>
            </Col>
            <Col flex='0 1 50'>
                <Spacer height={32} />
                <Button
                    variant={ButtonVariant.Tertiary}
                    dataTestId={`page-scoring-delete-${index}-${i}`}
                    isDestructive
                    icon={<IconBin title={`Delete page pattern ${i + 1}`} />}
                    onClick={() => {
                        deletePagePattern()
                    }}
                />
            </Col>
        </Flex>
    )
}

export const PagePatternEditor = React.memo(
    PagePatternEditorInner,
    (p1, p2) => JSON.stringify(p1, null, 2) === JSON.stringify(p2, null, 2)
)

function findDuplicate<T>(xs: T[]): T | undefined {
    const set = new Set<T>()
    for (const x of xs) {
        if (set.has(x)) {
            return x
        }
        set.add(x)
    }
    return undefined
}

export function validatePagePatternTags(tags: PagePatternTag[]): string | undefined {
    if (tags.length === 0) {
        return 'Required'
    }

    const tagNames = tags.filter((x) => x.tag !== '_').map((x) => x.tag)
    const dupTag = findDuplicate(tagNames)
    if (dupTag) {
        return `Duplicate tag: ${dupTag}`
    }

    for (const tag of tags) {
        const filtered = tag.subTags.filter((t) => t !== '_')
        const dupSubTag = findDuplicate(filtered)
        if (dupSubTag) {
            return `Duplicate sub-tag: ${tag.tag}{${dupSubTag}}`
        }
    }
}

export const PagePatternListEditor = ({
    index,
    entityId,
    pagePatterns,
    isCard,
}: {
    index: number
    entityId: string
    pagePatterns: PagePattern[] | undefined | null
    isCard: boolean
}) => {
    const id = `page-pattern-list-${index}-${entityId}`
    const errors = useMemo(() => {
        const variableNames = new Set<string>()
        return (pagePatterns || []).map((pagePattern) => {
            const error = {
                pageVariable: undefined as string | undefined,
                tags: undefined as string | undefined,
            }
            if (variableNames.has(pagePattern.pageVariable)) {
                error.pageVariable = 'Duplicate variable'
            }
            if (!pagePattern.pageVariable.length) {
                error.pageVariable = 'Required'
            }
            variableNames.add(pagePattern.pageVariable)
            error.tags = validatePagePatternTags(pagePattern.tags)
            return error
        })
    }, [pagePatterns])
    return (
        <View>
            <Text id={`${id}-heading`}>
                Page Patterns
                <ScreenReaderOnly> for selection step {index + 1}</ScreenReaderOnly>
            </Text>
            <Col gridGap='S200' id={id} aria-labelledby={`${id}-heading`} role='list'>
                {(pagePatterns || []).map((pagePattern, i) => (
                    <View key={i} role='listitem' aria-label={`Page Pattern ${i + 1}`}>
                        <PagePatternEditor
                            entityId={entityId}
                            index={index}
                            pagePatternIndex={i}
                            pagePattern={pagePattern}
                            isCard={isCard}
                            error={errors[i]}
                        />
                    </View>
                ))}

                <Row flex={1} gridGap='S200' justifyContent='flex-start'>
                    <Button
                        data-test-id={`add-selection-step-variable-${index}`}
                        onClick={() =>
                            BucketsAndCupsGroupHandler.updatePagePatterns(
                                entityId,
                                index,
                                (pagePatterns1) => [
                                    ...(pagePatterns1 || []),
                                    { pageVariable: '', tags: [] },
                                ]
                            )
                        }
                        variant={ButtonVariant.Tertiary}
                        size={isCard ? ButtonSize.Small : ButtonSize.Default}
                        icon={<IconPlus aria-hidden />}
                    >
                        Add Variable
                    </Button>
                </Row>
            </Col>
        </View>
    )
}

export const ExpandCollapsePagePatternsButton = (props: {
    selectionStep: BucketAndCupSelectionStepDTO
    index: number
    isCard: boolean
    isExpanded: boolean
    setIsExpanded: (expanded: boolean) => void
    entityId: string
}) => {
    const { isExpanded, setIsExpanded, index, entityId } = props
    const onClick = () => {
        setIsExpanded(!isExpanded)
    }
    const title = isExpanded ? 'Collapse Page Patterns' : 'Expand Page Patterns'
    const dataTestId = `${isExpanded ? 'collapse' : 'expand'}-page-patterns-${props.index}`
    const ariaControls = `page-pattern-list-${index}-${entityId}`
    if (props.isCard) {
        return (
            <Button
                dataTestId={dataTestId}
                onClick={onClick}
                aria-expanded={isExpanded}
                aria-controls={ariaControls}
            >
                {title}
            </Button>
        )
    } else {
        return (
            <Button
                dataTestId={dataTestId}
                size={ButtonSize.Small}
                icon={
                    isExpanded ? <IconChevronUp title={title} /> : <IconChevronDown title={title} />
                }
                onClick={onClick}
                aria-expanded={isExpanded}
                aria-controls={ariaControls}
            />
        )
    }
}

interface SelectStepRowProps {
    selectionStep: BucketAndCupSelectionStepDTO
    selectionStepIndex: number
    selectionStepsLength: number
    timingEnabled: boolean
    scoringEnabled: boolean
    entityId: string
    editDisabled?: boolean
}

const SelectionStepRowInner = (props: SelectStepRowProps) => {
    const { selectionStepsLength, timingEnabled, scoringEnabled, ...restProps } = props
    const [isExpanded, setIsExpanded] = useState(false)
    const pagePatterns = props.selectionStep.pagePatterns
    const index = props.selectionStepIndex
    const rest = {
        ...restProps,
        isTimerEnabled: timingEnabled,
    }

    return (
        <React.Fragment key={index}>
            <TrElement key={0}>
                <TdElement key={`${index}-bucket-rank`} width='10%'>
                    <BucketRankInput {...rest} />
                </TdElement>
                <TdElement key={`${index}-bucket-selection-rule`} width='16%'>
                    <BucketSelectionRuleInput {...rest} />
                </TdElement>
                {!timingEnabled && (
                    <TdElement key={`${index}-cup-rank`} width='10%'>
                        <CupRankInput {...rest} />
                    </TdElement>
                )}
                <TdElement key={`${index}-cup-selection-rule`} width='18%'>
                    <CupSelectionRuleInput {...rest} />
                </TdElement>
                <TdElement key={`${index}-number-of-cups-to-pick-from-bucket`} width='12%'>
                    <CupsToPickFromBucketInput {...rest} />
                </TdElement>
                {!timingEnabled && (
                    <TdElement key={`${index}-number-of-items-to-pick-from-cup`} width='12%'>
                        <ItemsToPickFromCupInput {...rest} />
                    </TdElement>
                )}
                {!timingEnabled && ON_TEST_ENVIRONMENT() && (
                    <TdElement key={`${index}-group-size`} width='12%'>
                        <GroupSizeInput {...rest} />
                    </TdElement>
                )}
                {timingEnabled && (
                    <TdElement key={`${index}-timing`} width='22%'>
                        <TimerConfigTypeInput {...rest} />
                    </TdElement>
                )}
                {timingEnabled && (
                    <TdElement key={`${index}-timing-elapsed-time`} width='14%'>
                        <ElapsedTimeInput {...rest} />
                    </TdElement>
                )}
                {scoringEnabled && (
                    <TdElement key={`${index}-add-scoring`} width='3%'>
                        <ExpandCollapsePagePatternsButton
                            entityId={props.entityId}
                            isExpanded={isExpanded}
                            setIsExpanded={setIsExpanded}
                            selectionStep={props.selectionStep}
                            index={index}
                            isCard={false}
                        />
                    </TdElement>
                )}
                <TdElement key={`${index}-delete-selection-step`} width='4%'>
                    <DeleteSelectionStepButton
                        entityId={props.entityId}
                        selectionStepIndex={props.selectionStepIndex}
                        selectionStepsLength={selectionStepsLength}
                        editDisabled={props.editDisabled}
                    />
                </TdElement>
            </TrElement>
            {isExpanded && scoringEnabled && (
                <TrElement key={1}>
                    <TdElement width='100%' colSpan={10}>
                        <PagePatternListEditor
                            entityId={props.entityId}
                            index={index}
                            pagePatterns={pagePatterns}
                            isCard={false}
                        />
                    </TdElement>
                </TrElement>
            )}
        </React.Fragment>
    )
}

const SelectionStepRow = React.memo(
    SelectionStepRowInner,
    (p1, p2) => JSON.stringify(p1, null, 0) === JSON.stringify(p2, null, 0)
)

const SelectionStepCard = (props: SelectStepRowProps) => {
    const {
        matches: { s: small },
    } = useBreakpoints()

    const [isExpanded, setIsExpanded] = useState(false)

    const { selectionStepsLength, timingEnabled, ...restProps } = props

    const inputProps: SelectionStepProps = {
        ...restProps,
        isTimerEnabled: timingEnabled && true,
        showLabel: true,
    }

    const INPUT_FLEX = `1 0 ${small ? '100%' : '200px'}`

    // TODO display page scoring
    return (
        <Card dataTestId={'selection-step-card'}>
            <Col>
                <Row>
                    <Text fontSize={'T300'}>Selection Step {props.selectionStepIndex + 1}</Text>
                </Row>
                <Spacer height='S200' />
                <Hr />
                <Spacer height='S300' />
                <Row gridGap='S300'>
                    <Col width='100%'>
                        <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                            <Col flex={INPUT_FLEX}>
                                <BucketRankInput {...inputProps} />
                            </Col>
                            <Col flex={INPUT_FLEX}>
                                <BucketSelectionRuleInput {...inputProps} />
                            </Col>
                            {!timingEnabled && (
                                <Col flex={INPUT_FLEX}>
                                    <CupRankInput {...inputProps} />
                                </Col>
                            )}
                            <Col flex={INPUT_FLEX}>
                                <CupSelectionRuleInput {...inputProps} />
                            </Col>
                            <Col flex={INPUT_FLEX}>
                                <CupsToPickFromBucketInput {...inputProps} />
                            </Col>
                            {!timingEnabled && (
                                <Col flex={INPUT_FLEX}>
                                    <ItemsToPickFromCupInput {...inputProps} />
                                </Col>
                            )}
                            {timingEnabled && (
                                <Col flex={INPUT_FLEX}>
                                    <TimerConfigTypeInput {...inputProps} />
                                </Col>
                            )}
                            {props.scoringEnabled && (
                                <Col flex={INPUT_FLEX}>
                                    <ExpandCollapsePagePatternsButton
                                        entityId={props.entityId}
                                        isExpanded={isExpanded}
                                        setIsExpanded={setIsExpanded}
                                        selectionStep={props.selectionStep}
                                        index={props.selectionStepIndex}
                                        isCard={false}
                                    />
                                </Col>
                            )}
                            {timingEnabled && (
                                <Col flex={INPUT_FLEX}>
                                    <ElapsedTimeInput {...inputProps} />
                                </Col>
                            )}
                        </Row>
                        {isExpanded && props.scoringEnabled && (
                            <Col width='100%'>
                                <Spacer height='S200' />
                                <Hr />
                                <Text>Page Scoring</Text>
                                <PagePatternListEditor
                                    isCard={true}
                                    entityId={props.entityId}
                                    index={props.selectionStepIndex}
                                    pagePatterns={props.selectionStep.pagePatterns}
                                />
                            </Col>
                        )}
                    </Col>
                    <View alignSelf={'center'}>
                        <DeleteSelectionStepButton
                            selectionStepsLength={selectionStepsLength}
                            entityId={props.entityId}
                            selectionStepIndex={props.selectionStepIndex}
                            editDisabled={props.editDisabled}
                        />
                    </View>
                </Row>
            </Col>
        </Card>
    )
}

export const SelectionStepEditor = ({
    entityId,
    selectionSteps,
    scoringEnabled,
    timingEnabled,
    editDisabled,
}: {
    entityId: string
    selectionSteps: BucketAndCupSelectionStepDTO[]
    scoringEnabled: boolean
    timingEnabled: boolean
    editDisabled?: boolean
}) => {
    const { matches } = useBreakpoints()

    // A Stencil bug which causes inputs within cells to lose focus on typing prevents us from using the Table tag.
    return (
        <Col gridGap='S200' dataTestId='selection-step-editor'>
            <View
                aria-live='assertive'
                aria-label={`Selection steps (${selectionSteps.length} selection steps)`}
            >
                Selection Steps
            </View>
            {timingEnabled && (
                <MessageBanner type={MessageBannerType.Informational}>
                    &quot;Cup Rank&quot; is 1 and &quot;Items to Pick from Cup&quot; is 1.
                </MessageBanner>
            )}
            {matches.m || matches.s ? (
                selectionSteps.map((p, index) => (
                    <SelectionStepCard
                        selectionStepIndex={index}
                        key={index}
                        selectionStep={p}
                        entityId={entityId}
                        selectionStepsLength={selectionSteps.length}
                        scoringEnabled={scoringEnabled}
                        timingEnabled={timingEnabled}
                        editDisabled={editDisabled}
                    />
                ))
            ) : (
                <TableElement isStriped>
                    <TheadElement>
                        <TrElement>
                            <ThElement>Bucket Rank</ThElement>
                            <ThElement>Bucket Selection Rule</ThElement>
                            {!timingEnabled && <ThElement>Cup Rank</ThElement>}
                            <ThElement>Cup Selection Rule</ThElement>
                            <ThElement>Cups to Pick from Bucket</ThElement>
                            {!timingEnabled && <ThElement>Items to Pick from Cup</ThElement>}
                            {!timingEnabled && ON_TEST_ENVIRONMENT() && (
                                <ThElement>Group Size</ThElement>
                            )}
                            {timingEnabled && <ThElement>Timing</ThElement>}
                            {timingEnabled && <ThElement>Elapsed Time</ThElement>}
                            {scoringEnabled && <ThElement>Page Patterns</ThElement>}
                            <ThElement>
                                <ScreenReaderOnly>Delete Selection Step</ScreenReaderOnly>
                            </ThElement>
                        </TrElement>
                    </TheadElement>
                    <TbodyElement>
                        {selectionSteps.map((p, index) => (
                            <SelectionStepRow
                                key={index}
                                selectionStep={p}
                                selectionStepIndex={index}
                                entityId={entityId}
                                selectionStepsLength={selectionSteps.length}
                                timingEnabled={timingEnabled}
                                scoringEnabled={scoringEnabled}
                                editDisabled={editDisabled}
                            />
                        ))}
                    </TbodyElement>
                </TableElement>
            )}
            <Row flex={1} gridGap='S200' justifyContent={'flex-end'}>
                <Button
                    id={addSelectionStepId(entityId)}
                    dataTestId='add-selection-step'
                    icon={<IconPlus aria-hidden={true} />}
                    variant={ButtonVariant.Tertiary}
                    aria-disabled={editDisabled}
                    onClick={() => BucketsAndCupsGroupHandler.addSelectionStep(entityId)}
                >
                    Add Selection Step
                </Button>
            </Row>
        </Col>
    )
}
